<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: false,
                addUrl: 'admin.ArticleCategory.add',
                addPerm: 'admin_ArticleCategory_add',
                editUrl: 'admin.ArticleCategory.edit',
                editPerm: 'admin_ArticleCategory_edit',
                delUrl: 'admin.ArticleCategory.delete',
                delPerm: 'admin_ArticleCategory_delete',
                listUrl: 'admin.ArticleCategory.tree',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {
                        prop: "parent_id",
                        label: "上级",
                        hide: true,
                        type: 'cascader',
                        dicUrl: 'admin.ArticleCategory.tree',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'}
                    },
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
